// NPM
import * as React from "react"
import { graphql } from 'gatsby'
import _ from 'lodash'

// Components
import Layout from "src/layouts/en"
import About from "src/components/pages/About/About";

const AboutPageEn = ({ location, data }) => {
  const topNav = data.topNav
  const sections = _.get(data, `aboutPage.sections`, [])
  const teamMembers = _.get(data, `teamMembers.nodes`, [])
  const advisors = _.get(data, `advisors.nodes`, [])
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.aboutPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <About 
          sections={ sections } 
          teamMembers={ teamMembers }
          advisors={ advisors }
        />
    </Layout>
  )
}

export default AboutPageEn

export const query = graphql`
  query AboutPageEnQuery($id: String) {
    topNav: strapiTopNav(locale: {eq: "en"}) {
      ...topNavContent
    }
    
    aboutPage: strapiPage(id: {eq: $id}) {
      ...pageInformation
    }

    teamMembers: allStrapiTeamMember(filter:{locale: {eq: "en"}}) {
      nodes {
        id
        shortName
        longName
        role
        order
        bio {
          data {
            bio
          }
        }
        avatar {
          url
        }
      }
    }

    advisors: allStrapiAdvisor(filter:{locale: {eq: "en"}}) {
      nodes {
        longName
        order
        bio {
          data {
            bio
          }
        }
      }
    }

    bottomNav: strapiBottomNav(locale: {eq: "en"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "en"}}) {
      nodes {
        ...socialMedia
      }
    }

  }
`